  import React from 'react'
  import Layout from '../../../../../components/App/Layout'
  import Navbar from '../../../../../components/App/NavbarRV'
  import Footer from '../../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../../assets/images/pages/1-5-2-3.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li><a href='/expertise-construction/expertise-batiment/'>Expertise technique batiment</a></li><li><a href='/expertise-construction/expertise-batiment/garanties-construction/'>Garanties de construction</a></li><li>GD / DO</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’assurance décennale, comment ça marche ?</h1>

<p>La loi Spinetta du 4 janvier 1978 encadre l'assurance construction en France. Elle rend notamment obligatoire :</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="GD / DO" />
</div>

<ul><li>la souscription d'une assurance dommages-ouvrage par les maîtres d'ouvrage (professionnels ou particuliers) pour permettre le préfinancement des réparations,</li>
<li>la souscription d'une assurance de responsabilité décennale par les professionnels du bâtiment.</li></ul>

<p>La garantie de responsabilité décennale fait partie des assurances obligatoires, au contraire de l'assurance de la garantie de bon fonctionnement qui n'est pas obligatoire. Par ailleurs, la garantie de parfait achèvement due par le constructeur à son client maître d'ouvrage pendant 1 an n'est quant à elle pas assurable.</p>

<h2>Le cas de la DOMMAGES OUVRAGE</h2>

<p>L’assurance dommages-ouvrage est une assurance obligatoire que doit souscrire tout maître d’ouvrage, notamment le particulier qui fait construire sa maison ou procède à une rénovation importante. Cette assurance doit être souscrite avant l’ouverture du chantier.</p>
<p>Elle est dite de préfinancement car elle intervient avant toute recherche de responsabilité décennale du constructeur. </p>

<p>Si une malfaçon survient dans les 10 ans qui suivent les travaux, elle permet de rembourser les dommages liés à la construction couverts par l' assurance décennale et ce, en dehors de toute recherche de responsabilité,</p>

<h2>En cas de conflit, faîtes-vous accompagner d’un Expert</h2>

<p>Les cas de conflits entre le Maître d'ouvrage et le constructeur ou le Maître d'œuvre sont bien connus. Bien souvent, le constructeur peut être tenté de minimiser voire de ne pas reconnaître les malfaçons ou désordres dont il a pourtant la responsabilité !</p>

<p>Afin d’assurer vos droits et de défendre vos intérêts, le concours d’un Expert technique indépendant permet généralement de régler ces conflits car l’intervention présuppose, en cas de non règlement du litige, la poursuite devant les tribunaux compétents avec l’appui d’un rapport d’Expert. </p>

<p>Des conflits peuvent également exister avec votre Dommages-ouvrage, bien que celui-ci est supposé vous défendre… En effet, il peut être tenté par votre Dommage-ouvrages de minimiser les causes des désordres ainsi que les solutions techniques à envisager pour réparer de façon pérenne votre bien !</p>

<p>Prenons un exemple d’une maison qui fissure au bout de la première année après réception. Vous sollicitez votre Dommages-Ouvrage qui préconise en solution de confortement la mise en place de drains périphériques aux fondations et la mise en place de gouttières d’évacuation des eaux pluviales.</p>

<p>Après expertise, votre Expert s’aperçoit qu’il y a absence d’étude de sol ou que celle-ci, si existante, mentionne la présence d’argiles particulièrement sensibles aux phénomènes de retrait et gonflement et que vos fondations sont insuffisamment ancrées dans un bon sol. </p>
<p>Une reprise en sous-oeuvre est alors nécessaire pour assurer la pérennité de votre bien. </p>
<p>Evidemment, la 2eme solution, celle qui est la plus adaptée, est bien plus coûteuse !</p>

<p>Afin de défendre vos droits et vos intérêts, faites vous accompagner d’un Expert technique construction indépendant.</p>

<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      

                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details